export const state = {
    appName:"Banktopup  Manager",
    user: {
        displayName:null,
        email:null,
        phoneNumber:null,
        photoURL:null,
        providerId:null,
        uid:null
    },
    error: null,
    loading: false,
    assess_token:null
}