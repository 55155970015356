import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import 'firebase/auth'
Vue.use(VueRouter)

const routes = [

  {
    path: '/home',
    name: 'home',
    meta:{
      requiresAuth:true
    },
    component: () => import('../views/home')
  },
  {
    path: '/api-document',
    name: 'api-document',
    meta:{
      requiresAuth:true
    },
    component: () => import('../views/api-document')
  },
  {
    path: '/products/all-app',
    name: 'products-all',
    meta:{
      requiresAuth:true
    },
    component: ()=> import('../views/all-app')
  },
  {
    path: '/products/:product',
    meta:{
      requiresAuth:true
    },
    props: true,
    component: ()=> import('../views/products')
  },
  {
    path: '/user/billing',
    name: 'user-billing',
    meta:{
      requiresAuth:true
    },
    component: () => import('../views/user/billing')
  },
  {
    path: '/user/settings*',
    name: 'user-settings',
    meta:{
      requiresAuth:true
    },
    component: () => import('../views/user/settings')
  },
  {
    path: '/auth',
    redirect: '/auth/signin'
  },
  {
    path: '/auth/:action',
    name: 'auth',
    props: true,
    component: () => import('../views/auth')
  },
  {
    path: "*",
    redirect:"/home",
  },
]
const allowAuthAction = ['signin','signup','reset','signout']

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {

  if ( ( to.name === 'auth' && to.params.action === undefined) || ( to.name === 'auth' && !allowAuthAction.includes(to.params.action.toLocaleLowerCase())  ) ){
    next('/auth/signin')
    return
  }

  if (to.path === "/"){
    next("/home")
    return;
  }
  const requiresAuth = to
      .matched
      .some(record => record.meta.requiresAuth)


  if ( to.name === 'auth' && to.params.action.toLocaleLowerCase() !== 'signout' && store.getters.isAuth){
    next("/home")
    return
  }

  if (requiresAuth && !store.getters.isAuth){
    next('/auth/signin')
  }
  next();


})
export default router
