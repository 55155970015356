<template>
    <div id="app">

        <Loading :active="getLoading"
                 :can-cancel="true"
                 :is-full-page="true"></Loading>
        <router-view/>

    </div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import {mapGetters} from "vuex";

export default {
    data() {
        return {
        }
    },
    components: {
        Loading
    },
    computed:{
        ...mapGetters(['getLoading']),

    }
}
</script>

<style lang="text/scss">


</style>
