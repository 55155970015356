import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'

export const actions = {
    async userSignUp({commit}, payload) {
        try {
            commit('setLoading', true)
            let firebaseUser = await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
            commit('setUser', firebaseUser.user)
            await router.push('/home')
        }catch (error) {
            commit('setError', error.message)
        }finally {
            commit('setLoading', false)
        }
    },
    async userSignIn({commit}, payload) {
        try {
            commit('setLoading', true)
            let firebaseUser = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
            commit('setUser', firebaseUser.user)
            commit('setError', null)
            await router.push('/home')
        }catch (error) {
            commit('setError', error.message)
        }finally {
            commit('setLoading', false)
        }
    },
    async userSignInWithFB({commit}){
        try {
            commit('setLoading', true)

            let provider = new firebase.auth.FacebookAuthProvider()
            provider.addScope('public_profile')
            provider.setCustomParameters({
                'display': 'popup'
            })
            let firebaseUser = await firebase.auth().signInWithPopup(provider)

            commit('setUser', firebaseUser.user)
            commit('setError', null)
            await router.push('/home')
        }catch (error) {
            commit('setError', error.message)
        }finally {
            commit('setLoading', false)
        }
    },
    async userSignInWithGitHub({commit}){
        try {
            commit('setLoading', true)

            let provider = new firebase.auth.GithubAuthProvider()
            provider.addScope('user')
            provider.setCustomParameters({
                'allow_signup': 'false'
            })
            let firebaseUser = await firebase.auth().signInWithPopup(provider)

            commit('setUser', firebaseUser.user)
            commit('setError', null)
            await router.push('/home')
        }catch (error) {
            commit('setError', error.message)
        }finally {
            commit('setLoading', false)
        }
    },
    async userSignInWithMicrosoft({commit}){
        try {
            commit('setLoading', true)

            let provider = new firebase.auth.OAuthProvider('microsoft.com');
            provider.setCustomParameters({
                // Force re-consent.
                prompt: 'consent',
                // login_hint: 'user@firstadd.onmicrosoft.com'
            });

            let firebaseUser = await firebase.auth().signInWithPopup(provider)

            commit('setUser', firebaseUser.user)
            commit('setError', null)
            await router.push('/home')
        }catch (error) {
            commit('setError', error.message)
        }finally {
            commit('setLoading', false)
        }
    },
    async userSignInWithGoogle({commit}){
        try {
            commit('setLoading', true)

            let provider = new firebase.auth.GoogleAuthProvider()
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile')

            let firebaseUser = await firebase.auth().signInWithPopup(provider)

            commit('setUser', firebaseUser.user)
            commit('setError', null)
            await router.push('/home')
        }catch (error) {
            commit('setError', error.message)
        }finally {
            commit('setLoading', false)
        }
    },
    async userReset({commit},payload){
        try {
            commit('setLoading', true)
            await firebase.auth().sendPasswordResetEmail(payload.email)

            commit('setError', null)
            return true
        }catch (error) {
            commit('setError', error.message)
            throw error
        }finally {
            commit('setLoading', false)
        }
    },
    autoSignIn({commit}, payload) {
        commit('setUser', payload)
    },
    async userSignOut({commit}) {
        await firebase
            .auth()
            .signOut()
        commit('setUser', null)
        commit('setAccessToken',null)
        localStorage.setItem("AccessToken",null)
    },
    async checkAuth ({ commit }) {
        return new Promise((resolve) => {
            firebase.auth().onAuthStateChanged(async (_user) => {
                if (_user) {
                    commit('setUser', _user);
                    if (firebase.auth().currentUser){
                        let token = await firebase.auth().currentUser.getIdToken()
                        if (token){
                            commit('setAccessToken',token)
                            localStorage.setItem("AccessToken",token)
                        }
                    }
                }
                resolve(true)
            })
        })
    },
    setAccessToken({commit},payload){
        localStorage.setItem("AccessToken",payload)
        commit('setAccessToken',payload)
    },
    setError({commit},payload){
        commit('setError',payload)
    },
    setLoading({commit}, payload) {
        commit('setLoading',payload)
    }
}