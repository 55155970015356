import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css'
import "./assets/css/app.css";



import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@sweetalert2/theme-material-ui/material-ui.scss'
Vue.use(VueSweetalert2);

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
    config: { id: "G-60B40SP4RV" }
});


import 'dayjs/locale/th'



import 'vue-loading-overlay/dist/vue-loading.css'


import numeral from 'numeral'
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
});

import VueFbCustomerChat from 'vue-fb-customer-chat'
Vue.use(VueFbCustomerChat, {
    page_id: 110583284440687, //  change 'null' to your Facebook Page ID,
    theme_color: '#0A7CFF', // theme color in HEX
    locale: 'th_TH', // default 'en_US'
})


Vue.config.productionTip = false

import firebase from 'firebase/app'
import 'firebase/auth'

import { firebaseConfig } from './config'
firebase.initializeApp(firebaseConfig);

(async ()=>{
    await store.dispatch('checkAuth')
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')

})()

